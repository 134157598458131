import React from 'react'
import { useDispatch } from 'react-redux'

import { setOpenQuiz } from '../../redux/actions/quiz'

import Logo from '../../assets/images/logo.svg'

const Footer: React.FC = () => {
	const dispatch = useDispatch()

	const onClickOpenQuizForm = () => {
		dispatch(setOpenQuiz(true))
	}

	return (
		<footer className='footer'>
			<div className="container">
				<div className="footer-wrapper">
					<img src={Logo} alt="" className="footer__logo" />

					<span className="footer__text">
						© Забота {new Date().getFullYear()}
					</span>

					<button className="btn small footer__btn" onClick={onClickOpenQuizForm}>
						Хочу узнать стоимость
					</button>
				</div>
			</div>
		</footer>
	)
}

export default Footer