import React from 'react'
import { compose } from "redux";

import { Home } from './pages'

import { Header, Footer } from './components'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
		ym: any;
	}
}

const App: React.FC = () => {
	return (
		<div className='wrapper' id="wrapper">
			<Header />

			<Home />

			<Footer />
		</div>
	)
}

export default App