import React from "react";
import { useDispatch } from "react-redux";

// import { setOpenQuiz } from "../redux/actions/quiz";

import { PopupForm } from "../components";

import HomeMainImage from "../assets/images/home-main-image.jpg";
import HomeMainImageMedia from "../assets/images/home-main-image-media.jpg";
import HomeChangeImage from "../assets/images/home-change-image.jpg";
import HomeOnlineImage from "../assets/images/home-online-image.jpg";
import HomeLocation from "../assets/images/map.jpg";

const Home: React.FC = () => {
    const dispatch = useDispatch();

    const [isOpenPopup, setIsOpenPopup] = React.useState(false);

    const onClickOpenForm = () => {
        setIsOpenPopup(true);
    };

    const onClickCloseForm = () => {
        setIsOpenPopup(false);
    };
    // const onClickOpenQuizForm = () => {
    //     dispatch(setOpenQuiz(true));
    // };

    return (
        <>
            <PopupForm isOpen={isOpenPopup} onClose={onClickCloseForm} />
            {/* <Quiz /> */}

            <section className="home-main">
                <div className="container">
                    <div className="home-main-wrapper">
                        <div className="home-main-text">
                            <p className="home-main-text__city">
                                Работаем во всех городах России
                            </p>

                            <h1 className="home-main-text__title">
                                Заботимся о ваших близких
                            </h1>

                            <p className="home-main-text__description">
                                Позаботимся о&nbsp;вашем родном человеке.
                                Искупаем, оденем, покормим, сменим белье, дадим
                                лекарства и&nbsp;будем рядом столько, сколько
                                нужно
                            </p>

                            <button
                                className="btn home-main-text__btn"
                                onClick={onClickOpenForm}
                            >
                                Хочу узнать стоимость
                            </button>
                        </div>

                        <picture>
                            <source
                                media="(max-width: 1350px)"
                                srcSet={HomeMainImageMedia}
                            />

                            <img
                                src={HomeMainImage}
                                alt=""
                                className="home-main__img"
                            />
                        </picture>
                    </div>
                </div>
            </section>

            <section className="home-change">
                <div className="container">
                    <div className="home-change-wrapper">
                        <div className="home-change-text">
                            <h2 className="home-change-text__title">
                                Не подойдёт специалист - предложим другого
                            </h2>
                            <p className="home-change-text__description">
                                Мы&nbsp;знаем, как сложно найти человека,
                                которому можно доверить жизнь своего близкого.
                                Поэтому в&nbsp;любой момент по&nbsp;вашему
                                запросу, можем поменять нашего специалиста
                                заботы
                            </p>
                        </div>

                        <img
                            src={HomeChangeImage}
                            alt=""
                            className="home-change__img"
                        />
                    </div>
                </div>
            </section>

            <section className="home-online">
                <div className="container">
                    <div className="home-online-wrapper">
                        <img
                            src={HomeOnlineImage}
                            alt=""
                            className="home-online__img"
                        />

                        <div className="home-online-text">
                            <h2 className="home-online-text__title">
                                Фото счастливых <br /> родных каждый день
                            </h2>
                            <p className="home-online-text__description">
                                Мы&nbsp;будем присылать фотоотчет
                                в&nbsp;мессенджер, чтобы вы&nbsp;понимали, что
                                мы&nbsp;приходим к&nbsp;вашему близкому, что
                                он&nbsp;счастлив и&nbsp;доволен, чист, сыт, одет
                                и&nbsp;о&nbsp;нём, действительно, заботятся
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-service">
                <div className="container">
                    <div className="home-service-wrapper">
                        <div className="home-service-block-wrapper">
                            <div className="home-service-block">
                                <span className="home-service-block__number">
                                    1
                                </span>

                                <h3 className="home-service-block__title">
                                    Не даем заскучать
                                </h3>

                                <p className="home-service-block__description">
                                    Проводим время с вашими родными так, чтобы
                                    им не было скучно или одиноко
                                </p>
                            </div>

                            <div className="home-service-block">
                                <span className="home-service-block__number">
                                    2
                                </span>

                                <h3 className="home-service-block__title">
                                    Всегда на связи
                                </h3>

                                <p className="home-service-block__description">
                                    Наша поддержка работает круглосуточно в
                                    мессенджере. Быстро ответим, где ваш
                                    близкий, и что с ним сейчас происходит
                                </p>
                            </div>

                            <div className="home-service-block">
                                <span className="home-service-block__number">
                                    3
                                </span>

                                <h3 className="home-service-block__title">
                                    Продлеваем жизнь
                                </h3>

                                <p className="home-service-block__description">
                                    По статистике люди, о которых заботятся,
                                    живут на 30% дольше, чем одинокие люди
                                </p>
                            </div>

                            <div className="home-service-block">
                                <span className="home-service-block__number">
                                    4
                                </span>

                                <h3 className="home-service-block__title">
                                    Развиваем
                                </h3>

                                <p className="home-service-block__description">
                                    Составим расписание занятий с вашим родным,
                                    чтобы интересно и полезно проводить
                                    свободное время
                                </p>
                            </div>
                        </div>

                        <button
                            className="btn home-service__btn"
                            onClick={onClickOpenForm}
                        >
                            Хочу узнать стоимость
                        </button>
                    </div>
                </div>
            </section>

            <section className="home-steps">
                <div className="home-steps-wrapper">
                    <h2 className="home-steps__title">
                        Что будет после того, как вы оставите заявку?
                    </h2>

                    <div className="home-steps-block-wrapper">
                        <div className="home-steps-block-line"></div>

                        <div className="home-steps-block">
                            <span className="home-steps-block__number">1</span>

                            <p className="home-steps-block__title">
                                Свяжемся с&nbsp;вами в&nbsp;месенджере
                            </p>
                        </div>

                        <div className="home-steps-block">
                            <span className="home-steps-block__number">2</span>

                            <p className="home-steps-block__title">
                                Подберем специалиста под ваш запрос
                            </p>
                        </div>

                        <div className="home-steps-block">
                            <span className="home-steps-block__number">3</span>

                            <p className="home-steps-block__title">
                                Согласуем время и&nbsp;дату приезда
                            </p>
                        </div>

                        <div className="home-steps-block">
                            <span className="home-steps-block__number">4</span>

                            <p className="home-steps-block__title">
                                Будем всегда с&nbsp;вами на&nbsp;связи для
                                решения любых вопросов
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-location">
                <div className="container">
                    <div
                        className="home-location-wrapper"
                        style={{ backgroundImage: `url("${HomeLocation}")` }}
                    >
                        <h2 className="home-location__title">
                            Где мы работаем?
                        </h2>

                        <p className="home-location__description">
                            Мы работаем по всей России
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
